import * as React from 'react'
import { graphql, type HeadFC } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'

const entries = [
  {
    title: 'Las Sagradas Escrituras',
    content: `En sus documentos originales, son divinamente inspiradas en todas sus partes. Las aceptamos, por tanto, como autoridad suprema y suficiente en todo lo concerniente a la doctrina y a la conducta cristiana.`,
  },
  {
    title: 'El Trino Dios',
    content: `Hay un Solo Dios, Creador de todas las cosas, quien existe eternamente en tres Personas: Padre, hijo y Espíritu Santo.`,
  },
  {
    title: 'El Señor Jesucristo',
    content: `Es Dios manifestado en carne, el Verbo encarnado. Según su humanidad, nació de la bienaventurada Virgen María, por obra y gracia del Espíritu Santo. Su vida humana fue libre de todo pecado y la Gloria de Dios fue manifestada a través de sus obras y de sus enseñanzas.`,
  },
  {
    title: `El Espíritu Santo`,
    content: `Es la tercera persona de la Santa Trinidad, quien lleva a cabo los designios del Trino Dios en toda esfera subjetiva. Descendió del cielo en el día de Pentecostés para habitar, tanto en el templo espiritual de la Iglesia, como los cuerpos de los redimidos; haciendo efectiva en sus vidas la santificación que se deriva de la Obra de la Cruz.`,
  },
  {
    title: 'La Obra de la Cruz',
    content: `Por la Obra de la Cruz, el Dios-Hombre se ofreció en Sacrificio único, de valor infinito, para expiar el pecado y satisfacer las justas demandas del Trono de Dios frente al hombre caído. Cristo resucitó corporalmente de entre los muertos y ascendió a la diestra de Dios, siendo el único y suficiente Salvador de los hombres y solo Mediador entre Dios y los hombres.`,
  },
  {
    title: 'La Iglesia',
    content: `Todos los verdaderos creyentes en Cristo forman, en su conjunto espiritual y por obra del Espíritu Santo, la Iglesia, que se llama el Cuerpo de Cristo. La Iglesia halla su reflejo en las iglesias locales, pero no se expresa por medio de organizaciones eclesiásticas.`,
  },
  {
    title: 'La Segunda Venida de Cristo',
    content:
      'Según sus claras promesas, el Señor Jesucristo ha de volver personalmente para recoger a los suyos y establecer su Reino en manifestación.',
  },
  {
    title: `La Resurrección de los muertos y el Juicio`,
    content: `Las Escrituras enseñan que habrá resurrección corporal de los muertos, tanto de los salvos como de los perdidos; los primeros para vida eterna y los segundos para condenación eterna.`,
  },
]

const BeliefsPage = () => {
  return (
    <Layout>
      <section className="flex min-h-screen flex-col px-4 py-8">
        <div className="sm:container sm:mx-auto">
          <h1 className="mb-8 text-3xl font-bold text-gray-800 sm:text-5xl">
            Lo que creemos
          </h1>
          <section className="flex flex-col">
            <div className="md:flex-1">
              <p className="mb-12 text-xl text-gray-500 sm:text-2xl">
                La Biblia define nuestra base doctrinal y regla de conducta.
              </p>
            </div>
            <div className="mb-8 flex flex-row items-center justify-start md:hidden">
              <StaticImage
                alt="Biblia en el púlpito"
                src="../images/biblia.jpeg"
                className="mb-4 w-[50%] rounded-md"
              />
            </div>
          </section>
          <section>
            <h2 className="mb-8 text-2xl font-bold text-gray-800 md:text-4xl">
              Base Doctrinal
            </h2>
            <div className="flex flex-col md:flex-row">
              <div className="flex-1 md:text-xl">
                {entries.map(({ title, content }, id) => (
                  <div key={id}>
                    <h3 className="mb-4 text-xl font-bold text-gray-800 md:mb-8 md:text-2xl">
                      {title}
                    </h3>
                    <p className="mb-8 text-slate-800">{content}</p>
                  </div>
                ))}
              </div>
              <div className="mb-8 hidden flex-1 flex-row items-start justify-center md:flex">
                <StaticImage
                  alt="Biblia en el púlpito"
                  src="../images/biblia.jpeg"
                  className="mb-4 rounded-md md:w-[70%] lg:w-[60%]"
                />
              </div>
            </div>
          </section>
        </div>
      </section>
    </Layout>
  )
}

export const Head: HeadFC = () => <title>Nuestras Creencias</title>
export default BeliefsPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
